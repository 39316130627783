<template>
<div class="content">
        <div class="content-top">
            <!-- 面包屑 -->
            <div class="breadcrumbs">
                <i></i>
                <a href="/">首页</a><span>></span>
                <a>新闻资讯</a><span>></span>
                <a href="#" class="active">{{ this.pagetitle }}</a>
            </div>
            <!-- 侧边栏 + 头部内容 -->
            <div class="top-sidebar">
                <!-- 侧边栏 -->
                
                <div class=" fl fw">
                  <div class="one">
                    <img class="one_img" src="/static/img/icon_title.png" alt="" />
                    <span class="one_span">{{ this.pagetitle }}</span>
                  </div>
                  <div class="h_div"></div>
                  <ul class="two" style="padding-top: 10px; padding-bottom: 10px;">
                    
                    <li class="two_li" v-for="(item, index) in list" :key="index" @click="handleDetail(item)">
                      <span id="content"> <a>{{ item.title.length > 41 ? item.title.slice(0,41) + '...' : item.title }} </a></span>
                      <p>{{ item.create_time }}</p>
                    </li>

                  </ul>
                  <div class="pages">
                    <a class="prev_btn" v-if="isfirst" @click="handlePage(curpage-1)">上一页</a>
                    <a class="num" v-for="(pindex, index) in pagelist" :key="index" v-bind:class="{act:curpage == pindex}" @click="handlePage(pindex)">{{ pindex }}</a>
                    <!-- <a class="num" v-bind:class="{act:curpage == totalpage}" @click="handlePage(totalpage)">{{ totalpage }}</a> -->
                    <a class="next_btn" v-if="islatest" @click="handlePage(curpage+1)">下一页</a>
                  </div>
                </div>
                <div class="right sidebar">
                    <ul>
                        <li>
                            <div class="sidebar-title active">
                                <span>公司资讯</span>
                            </div>
                            <!-- 子菜单 -->
                            <ul class="sidebar-health">   
                              <li  :class="this.cid == 1 ? 'health-active' : ''" @click="gotoAbout('/news/1')">颐悦要闻</li>
                              <li  :class="this.cid == 5 ? 'health-active' : ''" @click="gotoAbout('/news/5')">公司动态</li>
                              <li  :class="this.cid == 6 ? 'health-active' : ''" @click="gotoAbout('/news/6')">行业资讯</li>
                              <li  :class="this.cid == 2 ? 'health-active' : ''" @click="gotoAbout('/notice')">公示公告</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            
    </div>
    </div>

<div style="clear:both;"></div>

</template>

<style src="../../../public/static/css/claim-download.css" />
<style src="../../../public/static/css/news.css" />
<style>
  body{background: #f6f5f4 !important;}
  .two{padding-top: 10px; padding-bottom: 10px;font: 18px/34px Arial,Hiragino Sans GB,STHeiti,Helvetica Neue,Helvetica,Microsoft Yahei,WenQuanYi Micro Hei,sans-serif; color: #404040;}
  .fw{width:calc(100% - 207px);}
  .meta{color: #999 !important;font-size: 15px; margin-top: 5px !important;border-bottom: 1px solid #ccc; margin-bottom: 10px;}
</style>

<script>
import * as Api from '@/api'
export default {
  data() {
    return {
      cid: null,
      msg: "主页",
      curpage:1,
      totalpage:0,
      perpage:0,
      totalcount:0,
      list:[],
      isfirst: false,
      islatest: false,
      morethan5:false,
      pagelist:[],
      pagetitle:null
    }
  },
  created(){
    this.cid = this.$route.params.cid;
    this.loadData(1);
    if(this.cid == 1){
      this.pagetitle = '颐悦要闻';
    }else if(this.cid == 5){
      this.pagetitle = '公司动态';
    }else if(this.cid == 6){
      this.pagetitle = '行业资讯';
    }
  },
  methods:{
    loadData(topage){
      Api.news({page:topage, cid:this.cid}).then(response => {
        //return response.data.list
        var res = response.data.data.list
        console.log(res)
        this.curpage = res.current_page
        this.totalcount = res.total
        this.perpage = res.per_page
        this.totalpage = res.last_page
        this.list = res.data
        this.isfirst = this.curpage > 1
        this.islatest = this.curpage < this.totalpage
        let start = this.curpage - 2 <= 0 ? 1 : this.curpage - 2
        let end = this.curpage + 2 >= this.totalpage ? this.totalpage : this.curpage + 2
        
        this.pagelist = []
        for(let i = start; i <= end; i++) {
          this.pagelist.push(i)
        }
    })
   },
   handleDetail(item){
    // var topath = '/news/' + item.news_id
    // this.$router.push({path: topath})
    this.$router.push({name: 'NewsDetail', params:{id: item.article_id, cid:this.cid}})
   },
   handlePage(topage){
    this.loadData(topage)
   }
  }
};
</script>